<template>
  <section class="bando-head">
    <h1>Modifier un établissement</h1>
  </section>

  <div class="container">
    <section class="content">
      <!-- Buttons Gestion et attribution zones-->
      <div class="links">
        <router-link class="btn btn-fse" :to="{ name: 'ZonesVacations' }">
          <img src="@/assets/img/icon-zones.png" alt="Zones de vacations" />
          Gérer les zones de vacations
        </router-link>
        <router-link
          class="btn btn-fse"
          :to="{ name: 'AttributionFSE' }"
          v-if="isResponsable"
        >
          <img src="@/assets/img/icon-fse.png" alt="FSE" />
          Attribuer des FSE aux zones
        </router-link>
      </div>

      <form>
        <!-- INFOS ETABLISSEMENT -->
        <h2 class="title">Informations de l'établissement</h2>

        <div class="bloc-infos bloc-shadow">
          <div class="form-group row">
            <label for="regate" class="col col-12 col-md-3">Code Régate </label>
            <div class="col col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                name="regate"
                placeholder="Ex : 010080"
                v-model="etablissement.code_regate"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="etablissement" class="col col-12 col-md-3">
              Libellé établissement
            </label>
            <div class="col col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                name="etablissement"
                placeholder="Ex : AMBERIEU/BUGEY PPDC"
                v-model="etablissement.nom"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="cp" class="col col-12 col-md-3">Code postal </label>
            <div class="col col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                name="cp"
                placeholder="Ex : 01640"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="adresse" class="col col-12 col-md-3">Adresse</label>
            <div class="col col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                name="adresse"
                placeholder="Ex : 29 AVENUE ANDRE CITROEN - 01500 AMBERIEU EN BUGEY"
                v-model="adresse"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="dscc" class="col col-12 col-md-3">DSCC</label>
            <div class="col col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                name="dscc"
                placeholder="Ex : LYON DSCC"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="dex" class="col col-12 col-md-3">DEX</label>
            <div class="col col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                name="dex"
                placeholder="Ex : AUVERGNE RHONE ALPES"
              />
            </div>
          </div>
        </div>

        <!-- INFOS RESPONSABLE -->
        <!-- <h2 class="title">Responsable de l'établissement</h2>

        <div class="bloc-infos bloc-shadow">
          <div class="form-group row">
            <label for="nom" class="col col-12 col-md-3">Nom</label>
            <div class="col col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                name="nom"
                placeholder="Ex : MEAVILLA"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="preno" class="col col-12 col-md-3">Prénom</label>
            <div class="col col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                name="preno"
                placeholder="Ex : Christelle"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="email" class="col col-12 col-md-3">Email</label>
            <div class="col col-12 col-md-6">
              <input
                type="email"
                class="form-control"
                name="email"
                placeholder="Ex : christelle.meavilla@laposte.fr"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="tel" class="col col-12 col-md-3">Téléphone</label>
            <div class="col col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                name="tel"
                placeholder="Ex : 06 60 72 53 67"
              />
            </div>
          </div>
        </div> -->

        <!-- <p class="pl-5"><small>* Informations obligatoires</small></p> -->

        <!-- Buttons -->
        <div class="text-center mb-5 pb-5">
          <button type="reset" class="btn btn-secondary mx-3">
            Abandonner
          </button>
          <button type="submit" class="btn btn-primary mx-3">Valider</button>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "EtablissementEdit",
  components: {},
  data() {
    return {
      etablissement: {
        adresse: [
          {
            address_line4_number: "",
            address_line4_name: "",
            address_line6_zipcode: "",
            address_line6_city: ""
          }
        ]
      },
      error: false,
      loading: false
    };
  },
  created() {
    const route = useRoute();
    axios
      .get(process.env.VUE_APP_API + "/etablissement/" + route.params.id)
      .then(resp => {
        this.etablissement = resp.data;
      })
      .catch(() => {
        this.$swal.fire("Une erreur s'est produite.");
      });
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "isProfileLoaded",
      "isAdmin",
      "isFse",
      "isOperateur",
      "isResponsable"
    ]),
    adresse() {
      return (
        this.etablissement.adresse[0].address_line4_number +
        " " +
        this.etablissement.adresse[0].address_line4_name +
        " " +
        this.etablissement.adresse[0].address_line6_zipcode +
        " " +
        this.etablissement.adresse[0].address_line6_city
      ).trim();
    }
  }
};
</script>

<style scope lang="scss">
.content {
  .links {
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;

    .btn-fse {
      border: 2px solid var(--yellow);
      color: black;
      font-size: 2rem;
      margin: 0 1rem;

      &:hover {
        box-shadow: 0 0 2rem rgba(black, 0.2);
      }
    }
  }

  .bloc-shadow {
    margin-bottom: 3rem;
  }

  .bloc-infos {
    padding: 2rem 4rem 2rem 1rem;
    position: relative;

    .col {
      padding: 0 1rem;
    }

    .form-group {
      align-items: center;

      label {
        padding-bottom: 0;
        padding-top: 0;
        text-align: right;
      }
    }

    .btn {
      height: 4.5rem;
      padding: 1rem 2rem;
    }
  }
}
</style>
